@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
.editor-wrapper .mtk1 {
  color: #d4d4d4;
}
.editor-wrapper .mtk2 {
  color: #1e1e1e;
}
.editor-wrapper .mtk3 {
  color: #000080;
}
.editor-wrapper .mtk4 {
  color: #6a9955;
}
.editor-wrapper .mtk5 {
  color: #569cd6;
}
.editor-wrapper .mtk6 {
  color: #b5cea8;
}
.editor-wrapper .mtk7 {
  color: #646695;
}
.editor-wrapper .mtk8 {
  color: #c586c0;
}
.editor-wrapper .mtk9 {
  color: #9cdcfe;
}
.editor-wrapper .mtk10 {
  color: #f44747;
}
.editor-wrapper .mtk11 {
  color: #ce9178;
}
.editor-wrapper .mtk12 {
  color: #6796e6;
}
.editor-wrapper .mtk13 {
  color: #808080;
}
.editor-wrapper .mtk14 {
  color: #d16969;
}
.editor-wrapper .mtk15 {
  color: #dcdcaa;
}
.editor-wrapper .mtk16 {
  color: #4ec9b0;
}
.editor-wrapper .mtk17 {
  color: #c586c0;
}
.editor-wrapper .mtk18 {
  color: #4fc1ff;
}
.editor-wrapper .mtk19 {
  color: #c8c8c8;
}
.editor-wrapper .mtk20 {
  color: #cd9731;
}
.editor-wrapper .mtk21 {
  color: #b267e6;
}
.editor-wrapper .mtki {
  font-style: italic;
}
.editor-wrapper .mtkb {
  font-weight: bold;
}
.editor-wrapper .mtku {
  text-decoration: underline;
  text-underline-position: under;
}

.editor-wrapper .mtk100.Identifier.JsxElement.Bracket {
  color: #0080ff;
}

.editor-wrapper .mtk1000.Identifier.JsxOpeningElement.Bracket {
  color: #808080;
  font-weight: bold;
}

.editor-wrapper .mtk1001.Identifier.JsxClosingElement.Bracket {
  color: #808080;
  font-weight: lighter;
}

.editor-wrapper .mtk101.Identifier.JsxOpeningElement.Identifier {
  color: #569cd6;
}

.editor-wrapper .mtk102.Identifier.JsxClosingElement.Identifier {
  color: #569cd6;
  font-weight: lighter;
}

.editor-wrapper .mtk103.Identifier.JsxAttribute.Identifier {
  color: #9cdcfe;
}

.editor-wrapper .mtk104.JsxElement.JsxText {
  color: darkgoldenrod;
}

.editor-wrapper .mtk105.glyph.Identifier.JsxElement {
  background: #61dafb;
  opacity: 0.25;
}

.editor-wrapper .mtk12.Identifier.JsxExpression.JsxClosingElement {
  color: #ec5f67;
}

.editor-wrapper .mtk12.Identifier.JsxSelfClosingElement {
  color: #ec5f67;
}
.editor-wrapper .mtk12.Identifier.VariableStatement.JsxClosingElement {
  color: #ec5f67 !important;
}
.editor-wrapper .mtk12.VariableStatement.JsxSelfClosingElement.Identifier {
  color: #ec5f67;
}
.editor-wrapper .mtk12.Identifier.JsxAttribute.VariableDeclaration {
  color: crimson;
}
.editor-wrapper .mtk12.JsxExpression.VariableStatement {
  color: #fac863;
}
.editor-wrapper .mtk12.VariableStatement.JsxSelfClosingElement {
  color: #ede0e0;
}
.editor-wrapper .mtk12.VariableStatement.JsxClosingElement {
  color: #ede0e0;
}
.editor-wrapper .JsxText {
  color: #0c141f;
}

.editor-wrapper{
 position: relative;
 height: 100%;
  width: calc(100% - 10px);
}
.editor-wrapper .button-format{
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;
}
.editor-wrapper:hover .button-format{
  opacity: 1;
}
.preview-wrapper{
  position: relative;
  height: 100%;
  /* width: 100%; */
  flex-grow: 1;
}
.react-draggable-transparent-selection .preview-wrapper:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
} 
.preview-wrapper iframe {
  height: 100%;
  background-color: white;
  width: 100%;
  display: block;
}
.preview-error{
  position: absolute;
  top:10px;
  left: 10px;
  color:red;
}
.react-resizable-handle {
  display: block;
  background-color: #37414b;
  background-repeat: no-repeat;
  background-position: 50%;
}
 
.react-resizable-handle-s {
  height: 10px;
  width: 100%;
  cursor: row-resize;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
}
 
.react-resizable-handle-e {
  width: 10px;
  min-width: 10px;
  height: 100%;
  cursor: col-resize;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}
.resize-horizontal{
  display: flex;
  flex-direction: row;
}
.w-md-editor .title{
  line-height: unset ;
  font-size: unset ;
  font-weight: unset ;
}
.w-md-editor ul{
  line-height: 1.0;
}
.wrapper{
  margin-right: auto; /* 1 */
  margin-left:  auto; /* 1 */

  max-width: 960px; /* 2 */

  padding-right: 10px; /* 3 */
  padding-left:  10px; /* 3 */
}
.w-100{
  width: 100%;
}
.text-center{
  text-align: center;
}
.text-editor .w-md-editor-bar svg{
  display: none;
}
.text-editor .w-md-editor-bar{
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
  height: 11px;
  cursor: row-resize;
  background-color: #37414b;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 100%;
  position: relative;
}
.text-editor em {
  font-style: italic;
}

.text-editor .wmde-markdown hr {
  border-top: 1px solid #dee5ed;
}

.text-editor .wmde-markdown ol {
  list-style: decimal;
}

.text-editor .w-md-editor-show-live {
  /* Hide menu bar buttons to prevent accidental delete */
  z-index: 20;
}

.text-editor .w-md-editor-toolbar {
  background-color: #37414b;
  border-bottom: 1px solid gray;
}

.text-editor .w-md-editor-toolbar li button {
  color: #d4d4d4;
}

.text-editor .w-md-editor-content {
  background-color: #202123;
}

.text-editor .w-md-editor,
.text-editor .w-md-editor .w-md-editor-text-pre {
  color: #d4d4d4;
}

.text-editor .w-md-editor-text-pre .bold {
  color: unset;
}

.text-editor .token.list.punctuation {
  background-color: unset;
}

h1, h2, h3, h4, h5, h6,p,span {
  font-family: 'Nunito', sans-serif;
  border: 0;
    font-size: 100%;
    font: inherit;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    vertical-align: baseline;
    vertical-align: initial;
}
.banner{
  padding: 20px;
  margin: 20px auto;
  /* width: 100%; */
}
.banner h1{
  font-size: 64px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1;
  text-align: left;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; 
          line-clamp: 4; 
  -webkit-box-orient: vertical; */
  /* max-width: 600px; */
}
.banner h1 span{
  font-size: 64px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1;
  text-align: left;
  background-image: linear-gradient(to right bottom, rgb(150, 248, 255) 42%, rgb(0, 230, 255) 76%);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-box-decoration-break: clone;

}

.banner p{
  color: rgba(174, 221, 255, 0.8);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.64;
  max-width: 640px;
  margin-top: 40px;
}
.tab-view{
  padding: 20px;
  margin: 20px auto;
}
.tab-view h5{
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1;
}
.tab-view ul{
  margin: 20px auto;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: start;
}

.tab-view ul li{
  display: flex;
    margin: 8px;
    width: calc(25% - 16px);
    margin-top: 30px;
}

.tab-view ul li a{
  align-items: center;
  /* border-radius: 2px;
  box-shadow: 0 0 0 2px #aeddff1a; */
  border-radius: 6px;
    border: 2px solid #aeddff1a;
  color: #fff;
  display: flex;
  height: 84px;
  /* padding: 0 22px; */
  position: relative;
  text-align: left;
  transition: box-shadow .16s ease,background-color .16s ease;
  width: 100%;
  justify-content: start;
}
.tab-view ul li a img{
  margin-right: 10px;
}
.tab-view ul li a .lang{
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
  
}
.tab-view ul li a .lang span{
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  opacity: .8;
  text-transform: uppercase;
  transition: opacity .16s ease;
 }
 .tab-view ul li a .lang span:nth-child(2){
  margin-top: 5px;
  color: #aeddff;
  font-size: 11px;
  font-weight: 700;
 }
@media screen and (max-width: 768px){
  .banner h1, .banner h1 span{
    font-size: 32px;
  }
  .banner p{
    font-size: 12px;
    max-width: 50%;
  }
  .tab-view ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    padding-inline: 10px;
  }
  .tab-view ul li{
    margin: 2px -8px 0;
    width: calc(100% + 16px);
  }
  
}
@media screen and (max-width: 500px){
  .banner h1, .banner h1 span{
    font-size: 32px;
  }
  .banner p{
    font-size: 12px;
    max-width: 90%;
  }
  
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #353836;
  color: white;
  font-family: "Poppins", sans-serif;
}

#navbar a {
  text-decoration: none;
}

#navbar {
  /* background-color: #1d1f1d; */
  box-shadow: inset 2px 0px 3px 0px;
  background: transparent;
}
#content {
  padding: 0 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
}
#brand {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
}

#brand a {
  text-transform: uppercase;
	background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

ul {
  list-style: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

ul a {
  color: white;
}

ul li {
  padding: 5px;
  margin-left: 10px;
}

ul li:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.3s;
}

#login,
#signup {
  border-radius: 5px;
  padding: 5px 8px;
}

#login {
  border: 1px solid #498afb;
}

#signup {
  border: 1px solid #ff3860;
}

#signup a {
  color: #ff3860;
}

#login a {
  color: #498afb;
}

#hamburger-icon {
  margin: auto 0;
  display: none;
  cursor: pointer;
}

#hamburger-icon div {
  width: 35px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}

.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -8px);
  transform: rotate(45deg) translate(-6px, -8px);
}

.open .mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  /* height: calc(100vh - 50px);
  width: 100%;
  background-color: #fff; */
  height: auto;
    width: 100%;
    background-color: #fff;
   z-index: 5;
}

.mobile-menu li {
  margin-bottom: 10px;
}
.mobile-menu li a{
  color: #000;
}
@media only screen and (max-width: 600px) {
  #navbar nav {
    display: none;
  }

  #hamburger-icon {
    display: block;
  }
}

