@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

h1, h2, h3, h4, h5, h6,p,span {
  font-family: 'Nunito', sans-serif;
  border: 0;
    font-size: 100%;
    font: inherit;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    vertical-align: initial;
}
.banner{
  padding: 20px;
  margin: 20px auto;
  /* width: 100%; */
}
.banner h1{
  font-size: 64px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1;
  text-align: left;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; 
          line-clamp: 4; 
  -webkit-box-orient: vertical; */
  /* max-width: 600px; */
}
.banner h1 span{
  font-size: 64px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1;
  text-align: left;
  background-image: linear-gradient(to right bottom, rgb(150, 248, 255) 42%, rgb(0, 230, 255) 76%);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  background-clip: text;
  -webkit-box-decoration-break: clone;

}

.banner p{
  color: rgba(174, 221, 255, 0.8);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.64;
  max-width: 640px;
  margin-top: 40px;
}
.tab-view{
  padding: 20px;
  margin: 20px auto;
}
.tab-view h5{
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1;
}
.tab-view ul{
  margin: 20px auto;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: start;
}

.tab-view ul li{
  display: flex;
    margin: 8px;
    width: calc(25% - 16px);
    margin-top: 30px;
}

.tab-view ul li a{
  align-items: center;
  /* border-radius: 2px;
  box-shadow: 0 0 0 2px #aeddff1a; */
  border-radius: 6px;
    border: 2px solid #aeddff1a;
  color: #fff;
  display: flex;
  height: 84px;
  /* padding: 0 22px; */
  position: relative;
  text-align: left;
  transition: box-shadow .16s ease,background-color .16s ease;
  width: 100%;
  justify-content: start;
}
.tab-view ul li a img{
  margin-right: 10px;
}
.tab-view ul li a .lang{
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
  
}
.tab-view ul li a .lang span{
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  opacity: .8;
  text-transform: uppercase;
  transition: opacity .16s ease;
 }
 .tab-view ul li a .lang span:nth-child(2){
  margin-top: 5px;
  color: #aeddff;
  font-size: 11px;
  font-weight: 700;
 }
@media screen and (max-width: 768px){
  .banner h1, .banner h1 span{
    font-size: 32px;
  }
  .banner p{
    font-size: 12px;
    max-width: 50%;
  }
  .tab-view ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    padding-inline: 10px;
  }
  .tab-view ul li{
    margin: 2px -8px 0;
    width: calc(100% + 16px);
  }
  
}
@media screen and (max-width: 500px){
  .banner h1, .banner h1 span{
    font-size: 32px;
  }
  .banner p{
    font-size: 12px;
    max-width: 90%;
  }
  
}