.editor-wrapper{
 position: relative;
 height: 100%;
  width: calc(100% - 10px);
}
.editor-wrapper .button-format{
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;
}
.editor-wrapper:hover .button-format{
  opacity: 1;
}